<template>
  <q-dialog :ref="$options.name" persistent>
    <q-card style="min-width: 600px">
      <q-card-section>
        <div class="flex justify-between items-center">
          <h5>Manage User Account</h5>
          <close-button @click="hide" />
        </div>
      </q-card-section>

      <q-tabs
        v-model="tab"
        align="left"
        inline-label
        dense
        indicator-color="primary"
        active-color="primary"
        color="subtle"
        no-caps
      >
        <q-tab
          name="personalInfo"
          icon="sym_r_account_circle"
          label="Personal Info"
        />
        <q-tab name="security" icon="sym_r_lock" label="Security" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="personalInfo">
          <q-card-section class="q-pb-none">
            <p class="text-subtle">
              Update your personal information below.
            </p>
          </q-card-section>
          <q-form @submit="updateUserAccount" style="background: #fff">
            <q-card-section>
              <div class="row q-col-gutter-y-sm q-col-gutter-x-md">
                <div class="col-6">
                  <q-input
                    filled
                    v-model="personalInfoForm.firstName"
                    label="First Name"
                    lazy-rules
                    :rules="[
                      val => (val && val.length > 1) || 'Enter a first name.'
                    ]"
                    :disable="submitting"
                  />
                </div>
                <div class="col-6">
                  <q-input
                    filled
                    v-model="personalInfoForm.lastName"
                    label="Last Name"
                    lazy-rules
                    :rules="[
                      val => (val && val.length > 1) || 'Enter a last name.'
                    ]"
                    :disable="submitting"
                  />
                </div>
                <div class="col-12">
                  <q-input
                    filled
                    v-model="personalInfoForm.email"
                    label="Email Address"
                    lazy-rules
                    :rules="[
                      val =>
                        (val && val.length > 1) || 'Enter an email address.'
                    ]"
                    disable
                  />
                  <q-tooltip anchor="top middle">
                    Email Address cannot be changed. You must create a new user
                    account.
                  </q-tooltip>
                </div>
              </div>
            </q-card-section>

            <q-card-actions
              align="between"
              class="q-gutter-x-md q-px-md q-pb-md q-pt-none"
            >
              <q-btn
                flat
                label="cancel"
                color="subtle"
                v-close-popup
                :disable="submitting"
              />
              <q-btn
                label="Save Changes"
                type="submit"
                color="primary"
                :disable="submitting"
                :loading="submitting"
              />
            </q-card-actions>
          </q-form>
        </q-tab-panel>

        <q-tab-panel name="security">
          <q-list bordered>
            <q-expansion-item
              expand-separator
              icon="sym_r_password"
              label="Reset Password"
              group="userAccountEditorModalSecurityItems"
            >
              <q-card>
                <q-card-section>
                  We'll send you an email to reset your password and sign you
                  out.
                  <b>Are you sure you want to reset your password?</b>
                </q-card-section>
                <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
                  <q-btn
                    flat
                    label="cancel"
                    color="subtle"
                    v-close-popup
                    :disable="submitting"
                  />
                  <q-btn
                    label="Reset Password"
                    type="submit"
                    color="primary"
                    :disable="submitting"
                    :loading="submitting"
                    @click="sendResetPasswordEmail"
                  />
                </q-card-actions>
              </q-card>
            </q-expansion-item>

            <q-expansion-item
              expand-separator
              icon="sym_r_person_off"
              label="Delete Account"
              header-class="text-negative"
              group="userAccountEditorModalSecurityItems"
            >
              <q-card>
                <q-card-section>
                  This will permanently delete your user account. This action
                  cannot be undone.
                  <b>Are you sure you want to delete your user account?</b>
                </q-card-section>
                <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
                  <q-btn
                    flat
                    label="cancel"
                    color="subtle"
                    v-close-popup
                    :disable="submitting"
                  />
                  <q-btn
                    label="Delete Account"
                    type="submit"
                    color="negative"
                    :disable="submitting"
                    :loading="submitting"
                    @click="deleteUser"
                  />
                </q-card-actions>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-dialog>
</template>

<script>
import CloseButton from "@/components/UI/CloseButton.vue";

export default {
  name: "UserAccountEditorModal",
  components: { CloseButton },
  props: {},
  data() {
    return {
      tab: "personalInfo",
      submitting: false,
      personalInfoForm: {
        firstName: this.$store.state.user.record.first_name,
        lastName: this.$store.state.user.record.last_name,
        email: this.$store.state.user.record.email
      },
      changePasswordForm: {
        password: "",
        passwordConfirmation: ""
      }
    };
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    },
    updateUserAccount() {
      this.submitting = true;

      let updateParams = {
        id: this.$store.state.user.record.id,
        first_name: this.personalInfoForm.firstName,
        last_name: this.personalInfoForm.lastName
      };

      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "update",
          params: updateParams
        })
        .then(() => {
          this.$store.dispatch("user/POPULATE_RECORD");
          this.triggerActionOutcomeAlert("save", true);
          this.hide();
        })
        .catch(e => {
          console.log(e);
          if (e.response) {
            this.triggerActionOutcomeAlert(
              "create",
              false,
              e.response.data.errors[0].detail
            );
          } else {
            this.triggerActionOutcomeAlert("create", false);
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    sendResetPasswordEmail() {
      this.submitting = true;

      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "send-user-password-reset-email",
          params: {
            email: this.$store.state.user.record.email
          }
        })
        .then(() => {
          this.$router.push({ name: "Logout" });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("send", false);
        })
        .finally(() => {
          this.submitting = false;
          this.hide();
        });
    },
    deleteUser() {
      this.submitting = true;

      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "delete",
          params: { id: this.$store.state.user.record.id }
        })
        .then(() => {
          this.$router.push({ name: "Logout" });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("delete", false);
        })
        .finally(() => {
          this.submitting = false;
          this.hide();
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
