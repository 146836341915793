<template>
  <q-drawer
    dark
    show-if-above
    v-model="leftDrawerOpen"
    side="left"
    class="app-sidebar-drawer__content column"
    behavior="desktop"
    width="230"
    mini-width="62"
    elevated
    :mini="leftDrawerMini"
    @click="$store.commit('setLeftDrawerMini', false)"
  >
    <q-list class="app-menu">
      <q-item :to="{ name: 'PublisherAccountsOverview' }" exact>
        <q-item-section avatar>
          <q-avatar icon="sym_r_home" />
        </q-item-section>
        <q-item-section>
          Overview
        </q-item-section>
      </q-item>

      <q-item :to="{ name: 'PublisherReporting' }" exact>
        <q-item-section avatar>
          <q-avatar :icon="getAssetIcon('reporting')" />
        </q-item-section>
        <q-item-section>
          Reporting
        </q-item-section>
      </q-item>

      <q-item :to="{ name: 'PublisherComponentsOverview' }" exact>
        <q-item-section avatar>
          <q-avatar icon="sym_r_wysiwyg" />
        </q-item-section>
        <q-item-section>
          Accounts
        </q-item-section>
      </q-item>
    </q-list>

    <q-space />

    <q-list class="app-menu">
      <q-item />
      <q-expansion-item
        :model-value="adminExpanded"
        @update:model-value="val => (adminExpanded = val)"
        expand-separator
        :class="{
          'q-expansion-item--active': $router.currentRoute.value.path.includes(
            '/publisher/admin/'
          )
        }"
      >
        <template v-slot:header>
          <q-item-section avatar>
            <q-avatar icon="sym_r_settings" />
          </q-item-section>
          <q-item-section>
            Admin
          </q-item-section>
        </template>

        <q-item
          class="--inset-level-1"
          :to="{ name: 'PublisherAdminBilling' }"
          exact
        >
          <q-item-section>
            Billing
          </q-item-section>
        </q-item>
        <q-item
          class="--inset-level-1"
          :to="{ name: 'PublisherAdminBusinessSettings' }"
          exact
        >
          <q-item-section>
            Business Settings
          </q-item-section>
        </q-item>
        <q-item
          class="--inset-level-1"
          :to="{ name: 'PublisherAdminUserAccess' }"
          exact
        >
          <q-item-section>
            User Access
          </q-item-section>
        </q-item>
      </q-expansion-item>
    </q-list>
  </q-drawer>
</template>

<script>
export default {
  name: "ThePublisherLeftDrawer",
  data() {
    return {
      miniState: true,
      adminExpanded: false
    };
  },
  computed: {
    leftDrawerOpen() {
      return this.$store.state.leftDrawerOpen;
    },
    leftDrawerMini() {
      return this.$store.state.leftDrawerMini;
    }
  },
  watch: {
    leftDrawerMini(val) {
      if (val) this.adminExpanded = false;
    }
  }
};
</script>

<style scoped lang="scss"></style>
