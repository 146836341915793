<template>
  <DashboardSkeleton>
    <template v-slot:left-drawer>
      <ThePublisherLeftDrawer />
    </template>
  </DashboardSkeleton>
</template>

<script>
import DashboardSkeleton from "@/components/Skeletons/DashboardSkeleton";
import ThePublisherLeftDrawer from "@/components/ThePublisherLeftDrawer";

export default {
  name: "PublisherDashboard",
  components: {
    DashboardSkeleton,
    ThePublisherLeftDrawer
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss"></style>
