<template>
  <q-layout view="hHr Lpr lFr" class="dashboard">
    <the-titlebar />

    <slot name="left-drawer" />

    <q-ajax-bar
      ref="DashboardLoadingBar"
      position="top"
      color="primary"
      size="3px"
      skip-hijack
    />

    <q-page-container>
      <slot name="above-view" />
      <div class="page-content">
        <router-view v-if="!loadingView" v-slot="{ Component }">
          <transition appear name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <slot name="below-view" />
    </q-page-container>
  </q-layout>
</template>

<script>
import TheTitlebar from "@/components/TheTitlebar";

// TODO: Could put a timer here that is checked on revisiting the tab, and if over 24 hours retrigger an AUTH request

export default {
  name: "DashboardSkeleton",
  components: {
    TheTitlebar
  },
  data() {
    return {
      loadingView: false
    };
  },
  watch: {
    $route() {
      this.repopulateRequiredRecords();
    },
    loadingView(val, prev) {
      if (!val) {
        this.$refs["DashboardLoadingBar"].stop();
      } else if (val && val !== prev) {
        this.$refs["DashboardLoadingBar"].start();
      }
    }
  },
  mounted() {
    this.repopulateRequiredRecords();
  },
  methods: {
    repopulateRequiredRecords() {
      this.loadingView = true;

      let repopulateActions = [
        this.$store.dispatch("user/POPULATE_RECORD"),
        this.$store.dispatch("business/POPULATE_RECORD")
      ];

      Promise.all(repopulateActions)
        .then(() => {
          this.loadingView = false;
        })
        .catch(e => {
          console.log(e);
          this.$router.push({ name: "Logout" });
        });
    }
  }
};
</script>

<style lang="scss">
.dashboard {
  .page-content {
    padding: $space-xl $space-lg;
  }
}
</style>
