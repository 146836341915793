<template>
  <q-header elevated class="the-titlebar bg-background-dark text-white">
    <q-toolbar class="q-px-none q-gutter-x-md full-width">
      <q-btn
        dense
        flat
        round
        :ripple="false"
        icon="sym_r_menu"
        @click="$store.commit('toggleLeftDrawerMini')"
      />

      <q-toolbar-title shrink class="flex items-center q-px-none">
        <router-link
          to="/"
          class="flex text-white justify-center"
        >
          <img
            :src="$store.state.branding.logoLight"
            style="width: 145px; align-self: center;"
          />
        </router-link>

        <q-separator vertical class="q-mx-md" color="gray-medium-dark" />
        <p class="text-weight-medium">
          {{ $store.state.business.record.name }}
        </p>
      </q-toolbar-title>

      <!--<q-btn-dropdown
        stretch
        flat
        no-caps
        :label="workspace"
        content-style="min-width: 180px; width: 180px; max-width: 180px;"
        menu-anchor="bottom start"
        menu-self="top start"
      >
        <q-list dense separator>
          <div style="position: sticky; top: 0; background: #fff; z-index: 1;">
            <q-item dense>
              <q-item-section avatar>
                <Label text="Current Workspace" />
              </q-item-section>
            </q-item>
          </div>
          <q-item
            clickable
            v-close-popup
            :to="{ name: 'AdvertiserOverview' }"
            :disable="$store.state.business.record.advertiser_enabled == 0"
            :active="$route.path.includes('/advertiser')"
          >
            <q-item-section>
              <q-item-label>Advertiser</q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-avatar icon="sym_r_login" text-color="primary" />
            </q-item-section>
            <q-tooltip
              v-if="$store.state.business.record.advertiser_enabled == 0"
              anchor="center right"
              self="center left"
            >
              Please contact us if you wish to become an advertiser.
            </q-tooltip>
          </q-item>
          <q-item
            clickable
            v-close-popup
            :to="{ name: 'PublisherOverview' }"
            :disable="$store.state.business.record.publisher_enabled == 0"
          >
            <q-item-section>
              <q-item-label>Publisher</q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-avatar icon="sym_r_login" text-color="primary" />
            </q-item-section>
            <q-tooltip
              v-if="$store.state.business.record.publisher_enabled == 0"
              anchor="center right"
              self="center left"
            >
              Please contact us if you wish to become a publisher.
            </q-tooltip>
          </q-item>
        </q-list>
      </q-btn-dropdown>-->

      <q-space />

      <!--<q-btn dense round flat icon="sym_r_notifications_none">
        <q-badge color="red" floating rounded style="border: 2px solid black">
          2
        </q-badge>
      </q-btn>-->

      <q-btn-dropdown
        stretch
        flat
        no-caps
        dropdown-icon="sym_r_account_circle"
        no-icon-animation
      >
        <template v-slot:label>
          <div class="column items-end q-mr-sm">
            <p style="line-height: 1.1">
              {{ userDisplayName }}
            </p>
            <small class="text-weight-light" style="line-height: 1.1">
              {{
                $store.state.user.record.email.length > 43
                  ? $store.state.user.record.email.substr(0, 40) + "..."
                  : $store.state.user.record.email
              }}
            </small>
          </div>
        </template>
        <q-list style="min-width: 200px">
          <q-item
            clickable
            v-close-popup
            tabindex="0"
            @click="promptUserAccountEditor"
            class="no-wrap"
          >
            <q-item-section avatar>
              <q-icon size="sm" name="sym_r_manage_accounts" color="subtle" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="no-wrap">Manage Account</q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            clickable
            v-close-popup
            tabindex="0"
            @click="() => $router.push({ name: 'Logout' })"
          >
            <q-item-section avatar>
              <q-icon size="sm" name="sym_r_logout" color="subtle" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Sign Out</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-toolbar>
  </q-header>
</template>

<script>
import { /*mapMutations,*/ mapState } from "vuex";
import { Dialog } from "quasar";
import UserAccountEditorModal from "@/components/UserAccountEditorModal";
// import Label from "@/components/UI/Label";

export default {
  name: "TheTitlebar",
  components: { },
  data() {
    return {
      account: null
    };
  },
  computed: {
    workspace: {
      ...mapState({ get: "workspace" })
      // ...mapMutations({ set: "updateWorkspace" })
    },
    userDisplayName() {
      return this.$store.getters["user/displayName"].length > 40
        ? this.$store.getters["user/displayName"].substr(0, 37) + "..."
        : this.$store.getters["user/displayName"];
    }
  },
  methods: {
    promptUserAccountEditor() {
      Dialog.create({
        component: UserAccountEditorModal
      });
    }
  }
};
</script>

<style scoped lang="scss">
.the-titlebar {
  max-height: $the-titlebar-height;
  min-height: $the-titlebar-height;
}
</style>
